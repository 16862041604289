import daisyui from "daisyui";
import { Config } from "tailwindcss";
import defaultTheme from "tailwindcss/defaultTheme";

const config: Config = {
  content: ["./index.html", "./src/**/*.{ts,tsx}"],
  theme: {
    extend: {
      colors: {
        primary: "rgb(from var(--color-primary) r g b / <alpha-value>)",
        "content-primary":
          "rgb(from var(--color-content-primary) r g b / <alpha-value>)",
        "content-secondary":
          "rgb(from var(--color-content-secondary) r g b / <alpha-value>)",
        "bg-primary": "rgb(from var(--color-bg-primary) r g b / <alpha-value>)",
        "bg-secondary":
          "rgb(from var(--color-bg-secondary) r g b / <alpha-value>)",
        "bg-hover": "var(--color-bg-hover)",
        "content-info":
          "rgb(from var(--color-content-info) r g b / <alpha-value>)",
        "bg-info": "rgb(from var(--color-bg-info) r g b / <alpha-value>)",
        "content-success":
          "rgb(from var(--color-content-success) r g b / <alpha-value>)",
        "bg-success": "rgb(from var(--color-bg-success) r g b / <alpha-value>)",
        "content-warning":
          "rgb(from var(--color-content-warning) r g b / <alpha-value>)",
        "bg-warning": "rgb(from var(--color-bg-warning) r g b / <alpha-value>)",
        "content-danger":
          "rgb(from var(--color-content-danger) r g b / <alpha-value>)",
        "bg-danger": "rgb(from var(--color-bg-danger) r g b / <alpha-value>)",
        "border-primary":
          "rgb(from var(--color-border-primary) r g b / <alpha-value>)"
      },
      fontFamily: {
        sans: ["test", "serif", ...defaultTheme.fontFamily.sans]
      }
    }
  },
  darkMode: "selector",
  plugins: [daisyui],
  daisyui: {
    prefix: "daisyui-",
    themes: false,
    base: false,
    styled: true,
    utils: true,
    logs: false
  }
};

export default config;
