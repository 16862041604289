import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { useQuery } from "@apollo/client";
import { Notification, NotificationNew } from "@carbon/icons-react";
import clsx from "clsx";

export const NotificationsNavItemQuery = graphql(`
  query NotificationsNavItem {
    notifications(where: { read: false }) {
      totalCount
    }
  }
`);

export type NotificationsNavItemProps = {
  className?: string;
  onClick?: () => void;
};

export const NotificationsNavItem: React.FC<NotificationsNavItemProps> = ({
  className,
  onClick
}) => {
  const { t } = useTranslation();
  const { data } = useQuery(NotificationsNavItemQuery);

  const totalCount = data?.notifications.totalCount ?? 0;

  return (
    <Button
      href="/notifications"
      onClick={onClick}
      outline
      className={clsx(className)}
      title={t("notifications.navItem.htmlTitle", { count: totalCount })}
    >
      {totalCount > 0 ? (
        <NotificationNew data-slot="icon" />
      ) : (
        <Notification data-slot="icon" />
      )}
    </Button>
  );
};
