import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Transition,
  TransitionChild
} from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment, ReactNode } from "react";

export type ActionDialogProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  /**
   * Offset from the top of the screen. Do not forget to provide unit, eg: `100px`, `2rem` etc.
   *
   * Default: `"30vh"`
   */
  offsetTop?: string;
};

export const ActionDialog: React.FC<ActionDialogProps> = ({
  open,
  onClose,
  children,
  className,
  offsetTop = "30vh"
}) => {
  const MAX_HEIGHT = "100vh";
  const maxHeightCssVariableStyle = {
    "--max-height": `calc(${MAX_HEIGHT} - ${offsetTop} - env(safe-area-inset-top))`
  };
  const maxHeightClass = `max-h-[--max-height]`;

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 focus:outline-none"
        onClose={onClose}
      >
        <TransitionChild
          as={Fragment}
          enter="transition-opacity duration-300 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogBackdrop className="fixed inset-0 bg-zinc-950/50" />
        </TransitionChild>

        <div className={clsx("fixed inset-x-0 bottom-0")}>
          <div
            className={clsx(maxHeightClass)}
            style={{ ...maxHeightCssVariableStyle }}
          >
            <TransitionChild
              as={Fragment}
              enter="transition-transform duration-400 ease-out"
              enterFrom="translate-y-full opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transition-transform duration-300 ease-in"
              leaveFrom="translate-y-full opacity-100"
              leaveTo="translate-y-0 opacity-0"
            >
              <DialogPanel
                className={clsx(
                  className,
                  maxHeightClass,
                  "flex flex-col mx-auto max-w-md",
                  "safe-area-inset-bottom-padding",
                  "rounded-t-lg",
                  "bg-white dark:bg-zinc-900",
                  "ring-1 ring-zinc-950/10 dark:ring-white/10"
                )}
              >
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
