import { PlantsFilter } from "@/app/performance/PlantsFilter";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Divider } from "@/ui/divider";
import { GraphqlErrorAlert } from "@/ui/error/GraphqlErrorAlert";
import { Select } from "@/ui/form/select";
import { ListingPageWrapper } from "@/ui/ListingPageWrapper";
import { Heading } from "@/ui/typo/heading";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import {
  PlantPerformance,
  PlantPerformanceFragment
} from "../performance/PlantPerformance";

export const PerformancePageQuery = graphql(
  `
    query PerformancePage($plantId: Int!) {
      plant(plantId: $plantId) {
        ...PlantPerformance
      }
    }
  `,
  [PlantPerformanceFragment]
);

const CURRENT_YEAR = new Date().getFullYear();

export const PerformancePage = () => {
  const [year, setYear] = useState(CURRENT_YEAR - 1);
  const [quarter, setQuarter] = useState(4);
  const [plantId, setPlantId] = useState<string | undefined>();
  const { loading, error, data } = useQuery(PerformancePageQuery, {
    variables: { plantId: parseInt(plantId ?? "0", 10) },
    skip: !plantId
  });
  const { t } = useTranslation();

  if (error) {
    return <GraphqlErrorAlert error={error} />;
  }

  return (
    <ListingPageWrapper>
      <Heading>{t("performance.title")}</Heading>
      <Divider />
      <div className="flex flex-col md:flex-row md:gap-4">
        {/* TODO: works as multiple selection for now, so it's broken if user select more than 1 */}
        <PlantsFilter
          className="sm:w-96 mt-2"
          onChange={(items) => setPlantId(items[0]?.value)}
          selectFirstEntry
        />
        <div className="flex gap-4">
          <Select
            name="period"
            value={year}
            className="sm:w-32 mt-2"
            onChange={(e) => setYear(Number(e.target.value))}
          >
            {[...Array(5)].map((_, index) => {
              const optionYear = CURRENT_YEAR - index;
              return (
                <option key={optionYear} value={optionYear}>
                  {optionYear}
                </option>
              );
            })}
          </Select>
          <Select
            name="quarter"
            className="sm:w-32 mt-2"
            value={quarter}
            onChange={(e) => setQuarter(Number(e.target.value))}
          >
            {[...Array.from({ length: 4 })].map((_, i) => {
              const quarter = `Q${i + 1}`;

              return (
                <option key={quarter} value={i + 1}>
                  {quarter}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
      <PlantPerformance
        data={data?.plant}
        year={year}
        quarter={quarter}
        loading={loading}
      />
    </ListingPageWrapper>
  );
};
