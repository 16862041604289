import { UserForm, UserFormValues } from "@/app/user-management/UserForm";
import { UserItemFragment } from "@/app/user-management/UserItem";
import { UsersPageQuery } from "@/app/user-management/UsersPage";
import { useSession } from "@/lib/auth";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { Dialog, DialogTitle } from "@/ui/feedback/dialog";
import { toaster } from "@/ui/toast/Toaster";
import { useMutation } from "@apollo/client";
import { PlusIcon } from "@heroicons/react/20/solid";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";

const AddUserMutation = graphql(
  `
    mutation AddUser($input: CreateUserInput!) {
      addUser(createUserInput: $input) {
        ...UserItem
      }
    }
  `,
  [UserItemFragment]
);

export const AddUserDialogBtn: FC = () => {
  const { t } = useTranslation();
  const ability = useSession().ability;

  const [isOpen, setIsOpen] = useState(false);

  const [addUser, { loading }] = useMutation(AddUserMutation);

  const form = useForm<UserFormValues>();

  const handleSubmit = (formData: UserFormValues) => {
    addUser({
      variables: {
        input: {
          email: formData.email,
          displayName: formData.displayName,
          password: formData.password,
          phoneNumber: formData.phoneNumber || null,
          photoUrl: null,
          roleIds: formData.roles?.map((r) => parseInt(r, 10)) ?? []
        }
      },
      onCompleted: () => {
        setIsOpen(false);
        form.reset();
      },
      onError: () => {
        toaster.error({
          description: t(
            "userManagement.editForm.actions.add.error.description"
          )
        });
        form.setError("root", {
          type: "api",
          message: t("userManagement.editForm.actions.add.error.description")
        });
      },
      update: (cache, { data }) => {
        if (data?.addUser) {
          const existingUsers = cache.readQuery({
            query: UsersPageQuery
          });

          if (existingUsers?.users) {
            // Update the cache with the new user
            cache.writeQuery({
              query: UsersPageQuery,
              data: {
                users: [...existingUsers.users, data.addUser]
              }
            });
          }
        }
      }
    });
  };

  if (ability?.cannot("create", "users")) {
    return null;
  }

  return (
    <>
      <Button outline onClick={() => setIsOpen(true)} className="ml-auto">
        <PlusIcon />
        {t("userManagement.newUser")}
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          {t("userManagement.editForm.actions.add.title")}
        </DialogTitle>
        <UserForm
          form={form}
          onSubmit={handleSubmit}
          loading={loading}
          onClose={() => setIsOpen(false)}
        />
      </Dialog>
    </>
  );
};
