import { useSession } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import { ListingPageWrapper } from "@/ui/ListingPageWrapper";
import { Divider } from "@/ui/divider";
import { Heading } from "@/ui/typo/heading";
import { InvestorDashboard } from "./InvestorDashboard";
import { OwnerDashboard } from "./OwnerDashboard";

export const DashboardPage = () => {
  const { hasRole } = useSession();
  const { t } = useTranslation();

  const Dashboard =
    hasRole("investor") || hasRole("admin")
      ? InvestorDashboard
      : OwnerDashboard;

  return (
    <ListingPageWrapper>
      <Heading>{t("dashboard.title")}</Heading>
      <Divider />

      <Dashboard />
    </ListingPageWrapper>
  );
};
