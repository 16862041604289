import * as Headless from "@headlessui/react";
import clsx from "clsx";
import { LayoutGroup, motion } from "framer-motion";
import React, { Fragment, forwardRef, useId } from "react";
import { TouchTarget } from "../button/button";
import { Link } from "../link";

export const Sidebar = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"nav">) => {
  return (
    <nav
      {...props}
      className={clsx(className, "flex h-full min-h-0 flex-col gap-y-3")}
    />
  );
};

export const SidebarHeader = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      data-slot="header"
      className={clsx(
        className,
        "flex flex-col p-4 [&>[data-slot=section]+[data-slot=section]]:mt-2.5",
        "bg-bg-primary rounded-2xl"
      )}
    />
  );
};

export const SidebarBody = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      className={clsx(
        className,
        "flex flex-1 flex-col overflow-y-auto p-2 [&>[data-slot=section]+[data-slot=section]]:mt-8",
        "bg-bg-primary rounded-2xl"
      )}
    />
  );
};

export const SidebarFooter = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      className={clsx(
        className,
        "flex flex-col p-4 [&>[data-slot=section]+[data-slot=section]]:mt-2.5",
        "bg-bg-primary rounded-2xl"
      )}
    />
  );
};

export const SidebarSection = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"div">) => {
  const id = useId();

  return (
    <LayoutGroup id={id}>
      <div
        {...props}
        data-slot="section"
        className={clsx(className, "flex flex-col")}
      />
    </LayoutGroup>
  );
};

export const SidebarDivider = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"hr">) => {
  return (
    <hr
      {...props}
      className={clsx(
        className,
        "my-4 border-t border-border-primary lg:-mx-2"
      )}
    />
  );
};

export const SidebarSpacer = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      aria-hidden="true"
      {...props}
      className={clsx(className, "mt-8 flex-1")}
    />
  );
};

export const SidebarHeading = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"h3">) => {
  return (
    <h3
      {...props}
      className={clsx(
        className,
        "mb-1 px-2 text-xs/6 font-medium text-content-primary"
      )}
    >
      {props.children}
    </h3>
  );
};

export type SidebarItemProps = {
  current?: boolean;
  currentIndicator?: boolean;
  className?: string;
  children: React.ReactNode;
} & (
  | Omit<Headless.ButtonProps, "className">
  | Omit<React.ComponentPropsWithoutRef<typeof Link>, "type" | "className">
);

export const SidebarItem = forwardRef(function SidebarItem(
  {
    current,
    currentIndicator,
    className,
    children,
    ...props
  }: SidebarItemProps,
  ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>
) {
  const classes = clsx(
    // Base
    "flex w-full items-center gap-3 rounded-lg px-2 py-2.5 text-left text-base/6 font-medium text-content-secondary sm:py-4 sm:text-sm/5",
    // Leading icon/icon-only
    "data-[slot=icon]:*:size-6 data-[slot=icon]:*:shrink-0 data-[slot=icon]:*:fill-content-secondary sm:data-[slot=icon]:*:size-5",
    // Trailing icon (down chevron or similar)
    "data-[slot=icon]:last:*:ml-auto data-[slot=icon]:last:*:size-5 sm:data-[slot=icon]:last:*:size-4",
    // Avatar
    "data-[slot=avatar]:*:-m-0.5 data-[slot=avatar]:*:size-7 data-[slot=avatar]:*:[--ring-opacity:10%] sm:data-[slot=avatar]:*:size-6",
    // Hover
    "data-[hover]:text-content-primary data-[hover]:bg-bg-hover data-[slot=icon]:*:data-[hover]:fill-content-primary",
    // Active
    "data-[hover]:text-content-primary data-[active]:bg-bg-hover data-[slot=icon]:*:data-[active]:fill-content-primary",
    // Current
    "data-[slot=icon]:*:data-[current]:fill-content-primary data-[current]:text-content-primary",
    // Transitions
    "transition-colors duration-300 ease-in-out"
  );

  return (
    <span className={clsx(className, "relative")}>
      {current && currentIndicator && (
        <motion.span
          layoutId="current-indicator"
          className="absolute inset-y-2 -left-2 w-0.5 rounded-full bg-content-primary"
        />
      )}
      {"href" in props ? (
        <Headless.CloseButton as={Fragment} ref={ref}>
          <Link
            className={classes}
            {...props}
            data-current={current ? "true" : undefined}
          >
            <TouchTarget>{children}</TouchTarget>
          </Link>
        </Headless.CloseButton>
      ) : (
        <Headless.Button
          {...props}
          className={clsx("cursor-default", classes)}
          data-current={current ? "true" : undefined}
          ref={ref}
        >
          <TouchTarget>{children}</TouchTarget>
        </Headless.Button>
      )}
    </span>
  );
});

export const SidebarLabel = ({
  className,
  ...props
}: { className?: string } & React.ComponentPropsWithoutRef<"span">) => {
  return <span {...props} className={clsx(className, "truncate")} />;
};
