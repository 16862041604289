import { AuthError } from "@/lib/auth";
import { mapFirebaseErrorKey } from "@/lib/auth/firebaseErrorTranslator";
import { useRecaptcha } from "@/lib/auth/multi-factor-authentication/useRecaptcha";
import { useTranslation } from "@/lib/i18n";
import { atom, useAtom } from "jotai";
import { PropsWithChildren, useState } from "react";
import { secondFactorFlowAtom, secondFactorStepAtom } from "../atoms/mfaAtoms";
import { BackToLoginLink } from "../BackToLoginLink";
import { SendVerificationCode } from "./SendVerificationCode";
import { SmsVerificationForm } from "./SmsVerificationForm";

const currentStep = atom((get) => get(secondFactorStepAtom) ?? "send_code");

export const VerifySecondFactorFlow: React.FC = () => {
  const { t } = useTranslation();
  const recaptcha = useRecaptcha();
  const [step] = useAtom(currentStep);
  const [, setStep] = useAtom(secondFactorStepAtom);
  const [, setFlow] = useAtom(secondFactorFlowAtom);

  const [error, setError] = useState<string | null>(null);

  const handleError = (err: unknown) => {
    const errorKey = mapFirebaseErrorKey(err as AuthError);
    const errorMessage = t(`errors.auth.${errorKey}`, {
      ns: "common"
    } as unknown as string);

    setError(errorMessage);
  };

  return (
    <div>
      {error ? <ErrorBox>{error}</ErrorBox> : null}

      {recaptcha.error && <ErrorBox>{recaptcha.error.message}</ErrorBox>}

      <div>
        {step === "send_code" ? (
          <SendVerificationCode
            onSuccess={() => {
              setStep("verify");
            }}
            onError={handleError}
          />
        ) : null}

        {step === "verify" ? (
          <SmsVerificationForm
            onSuccess={async () => {
              setStep("completed");
              recaptcha.clear();
              await new Promise((resolve) => setTimeout(resolve, 500));
              setFlow(null);
              setStep(null);
            }}
            onError={handleError}
          />
        ) : null}
      </div>

      {step === "completed" ? (
        <div className="mt-8">
          <div className="text-green-600 text-center">
            {t("mfa.completed.success.verifySecondFactor")}
          </div>
        </div>
      ) : null}

      {step !== "completed" ? (
        <div className="mt-1">
          <BackToLoginLink />
        </div>
      ) : null}
    </div>
  );
};

const ErrorBox = ({ children }: PropsWithChildren) => {
  return (
    <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
      {children}
    </div>
  );
};
