import { graphql } from "@/lib/data/graphql";
import { Badge } from "@/ui/display/badge";
import { useQuery } from "@apollo/client";

export const NotificationsBadgeQuery = graphql(`
  query NotificationsBadge {
    notifications(where: { read: false }) {
      totalCount
    }
  }
`);

/**
 * @deprecated
 */
export const NotificationsBadge: React.FC = () => {
  const { data, loading } = useQuery(NotificationsBadgeQuery);
  if (loading) {
    return null;
  }

  const totalCount = data?.notifications.totalCount;
  if (!totalCount || totalCount === 0) {
    return null;
  }

  return (
    <Badge className="ml-auto !rounded-full !font-bold" color="primary">
      {totalCount > 99 ? "99+" : totalCount}
    </Badge>
  );
};
