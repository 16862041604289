import { useAddResource } from "@/app/permissions/hooks/useAddPermission";
import { useUpdateRole } from "@/app/permissions/hooks/useUpdateRole";
import { RolePermissionItem } from "@/app/permissions/RolePermissionItem";
import { useSession } from "@/lib/auth";
import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { LoadingSpinner } from "@/ui/loading-spinner";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow
} from "@/ui/table/table";
import { Text } from "@/ui/typo/text";
import { useCallback, useEffect, useMemo, useState } from "react";

export const PermissionRoleItemFragment = graphql(`
  fragment PermissionRoleItem on Role {
    id
    resources {
      id
      name
    }
  }
`);

export const PermissionItemFragment = graphql(`
  fragment PermissionItem on Resource {
    id
    name
  }
`);

export const useSubjectActionMap = (
  roles: FragmentOf<typeof PermissionRoleItemFragment>[] | undefined,
  selectedRoleId: string | undefined
) => {
  const [subjectActionMap, setSubjectActionMap] = useState<
    Record<string, Set<string>>
  >({});

  useEffect(() => {
    if (!selectedRoleId) return;

    const selectedRole = roles?.find(
      (role) =>
        readFragment(PermissionRoleItemFragment, role).id.toString() ===
        selectedRoleId
    );

    if (selectedRole) {
      const resources = readFragment(
        PermissionRoleItemFragment,
        selectedRole
      ).resources;

      const map: Record<string, Set<string>> = {};
      resources.forEach((permission) => {
        const [subject, action] = permission.name.split(":");
        if (subject && !map[subject]) {
          map[subject] = new Set();
        }
        if (subject && action) {
          map[subject]?.add(action);
        }
      });

      setSubjectActionMap(map);
    }
  }, [roles, selectedRoleId]);

  return { subjectActionMap };
};

const useSubjectsAndActions = (
  allPermissions: FragmentOf<typeof PermissionItemFragment>[] | undefined
) => {
  return useMemo(() => {
    const subjectsSet = new Set<string>();
    const actionsSet = new Set<string>();

    allPermissions?.forEach((permissionData) => {
      const permission = readFragment(PermissionItemFragment, permissionData);
      const [subject, action] = permission.name.split(":");

      subject && subjectsSet.add(subject);
      action && actionsSet.add(action);
    });

    return {
      subjects: Array.from(subjectsSet).sort((a, b) => a.localeCompare(b)), // Sort subjects alphabetically
      actions: Array.from(actionsSet).sort((a, b) => a.localeCompare(b)) // Sort actions alphabetically
    };
  }, [allPermissions]);
};

type Props = {
  roles?: FragmentOf<typeof PermissionRoleItemFragment>[];
  allPermissions?: FragmentOf<typeof PermissionItemFragment>[];
  selectedRoleId?: string;
  loading?: boolean;
};

export const RolePermissionsTable: React.FC<Props> = ({
  roles,
  allPermissions,
  selectedRoleId,
  loading
}) => {
  const { t } = useTranslation();

  const { addResource } = useAddResource();
  const { updateRole } = useUpdateRole();
  const ability = useSession().ability;
  const hasUpdatePermission =
    ability?.can("update", "roles") && ability?.can("update", "resources");

  const { subjects, actions } = useSubjectsAndActions(allPermissions);
  const { subjectActionMap } = useSubjectActionMap(roles, selectedRoleId);

  const ensurePermissionExists = useCallback(
    async (subject: string, action: string) => {
      const permissionName = `${subject}:${action}`;

      const exists = allPermissions?.some(
        (permission) =>
          readFragment(PermissionItemFragment, permission).name ===
          permissionName
      );

      if (!exists) {
        // create the permission if it doesn't exist
        await addResource({
          variables: {
            name: permissionName.includes(":")
              ? permissionName.toLowerCase()
              : permissionName.toLowerCase() + ":read"
          }
        });
      }
    },
    [allPermissions, addResource]
  );

  const handleTogglePermission = useCallback(
    async (subject: string, action: string) => {
      if (!hasUpdatePermission) {
        return;
      }

      await ensurePermissionExists(subject, action);

      const updatedMap = deepCloneState(subjectActionMap);

      if (updatedMap[subject]?.has(action)) {
        updatedMap[subject].delete(action);
      } else {
        updatedMap[subject] = updatedMap[subject] || new Set();
        updatedMap[subject].add(action);
      }

      const newResources = Object.entries(updatedMap).flatMap(([sub, acts]) =>
        Array.from(acts).map((act) => `${sub}:${act}`)
      );

      if (selectedRoleId) {
        updateRole({
          variables: {
            roleId: parseInt(selectedRoleId, 10),
            input: {
              resourceNames: newResources
            }
          }
        });
      }
    },
    [
      hasUpdatePermission,
      ensurePermissionExists,
      selectedRoleId,
      subjectActionMap,
      updateRole
    ]
  );

  if (loading) {
    return (
      <div className="flex mt-14 items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (
    !roles ||
    roles.length === 0 ||
    !allPermissions ||
    allPermissions.length === 0
  ) {
    return <Text>{t("permissions.listing.noData")}</Text>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>{t("permissions.listing.fields.subject")}</TableHeader>
          {actions.map((action) => (
            <TableHeader
              key={action}
              className="w-[100px] font-bold dark:text-white"
            >
              {action.toUpperCase()}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {subjects.map((subject) => (
          <RolePermissionItem
            key={subject}
            subject={subject}
            actions={actions}
            subjectActionMap={subjectActionMap}
            togglePermission={handleTogglePermission}
            disabled={!hasUpdatePermission}
          />
        ))}
      </TableBody>
    </Table>
  );
};

// deep clone state based on set
const deepCloneState = (
  state: Record<string, Set<string>>
): Record<string, Set<string>> => {
  const clonedState: Record<string, Set<string>> = {};
  for (const key in state) {
    if (Object.hasOwn(state, key)) {
      clonedState[key] = new Set(state[key]);
    }
  }
  return clonedState;
};
