import { TableHeader } from "@/ui/table/table";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";

type TableHeaderSortableProps<T extends string> = {
  column: T;
  label: string;
  orderBy?: Partial<Record<string, "ASC" | "DESC" | null>> | null;
  onOrderBy: (column: T) => void;
  className?: string;
};

export const TableHeaderSortable = <T extends string>({
  column,
  label,
  orderBy,
  onOrderBy,
  className
}: TableHeaderSortableProps<T>) => {
  const direction = orderBy?.[column];

  return (
    <TableHeader className={className}>
      <button
        className="flex gap-1 items-center"
        onClick={() => onOrderBy(column)}
      >
        {label}
        <div className="w-[16px]">
          {direction === "DESC" ? (
            <ArrowDownIcon />
          ) : direction === "ASC" ? (
            <ArrowUpIcon />
          ) : null}
        </div>
      </button>
    </TableHeader>
  );
};
