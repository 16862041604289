import { useTranslation } from "@/lib/i18n";
import { ListingPageWrapper } from "@/ui/ListingPageWrapper";
import { Divider } from "@/ui/divider";
import { Heading } from "@/ui/typo/heading";

export const MyImpactPage = () => {
  const { t } = useTranslation();
  return (
    <ListingPageWrapper>
      <Heading>{t("myImpact.title")}</Heading>
      <Divider />
    </ListingPageWrapper>
  );
};
