import { graphql } from "@/lib/data/graphql";
import { useMutation } from "@apollo/client";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";

const RegisterDeviceMutation = graphql(`
  mutation RegisterDevice($input: RegisterDeviceInput!) {
    registerDevice(input: $input) {
      success
    }
  }
`);

/**
 * Registers device and the FCM token. @capacitor/device plugin is cross platform and in case
 * of browser it will return a random string as the device identifier.
 *
 * More at https://capacitorjs.com/docs/apis/device
 */
export const useRegisterDevice = () => {
  const [runMutation, result] = useMutation(RegisterDeviceMutation);

  const registerDevice = async (fcmToken: string) => {
    const deviceId = (await Device.getId()).identifier;
    const deviceInfo = await Device.getInfo();

    const input = {
      fcmToken,
      deviceId,
      platform: Capacitor.getPlatform(),
      metadata: deviceInfo as unknown as Record<string, unknown>
    };

    return runMutation({
      variables: {
        input
      }
    });
  };

  return {
    registerDevice,
    ...result
  };
};
