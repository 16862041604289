import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Dialog, DialogTitle } from "@/ui/feedback/dialog";
import { toaster } from "@/ui/toast/Toaster";
import { useMutation } from "@apollo/client";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { UserForm, UserFormValues } from "./UserForm";

// TODO: resolve how to solve this, custom fragment? circular deps?
export const UpdateUserDialogFragment = graphql(`
  fragment UpdateUserDialog on User {
    id
    uid
    email
    displayName
    phoneNumber
    roles {
      id
      name
    }
  }
`);

const UpdateUserMutation = graphql(
  `
    mutation UpdateUser($userId: String!, $input: UpdateUserInput!) {
      updateUser(userId: $userId, updateUserInput: $input) {
        ...UpdateUserDialog
      }
    }
  `,
  [UpdateUserDialogFragment]
);

type Props = {
  data: FragmentOf<typeof UpdateUserDialogFragment>;
  isOpen: boolean;
  onClose: () => void;
};

export const UpdateUserDialog: FC<Props> = ({ data, onClose, isOpen }) => {
  const { t } = useTranslation();

  const user = readFragment(UpdateUserDialogFragment, data);

  const [updateUser, { loading }] = useMutation(UpdateUserMutation);
  const form = useForm<UserFormValues>({
    defaultValues: {
      email: user.email ?? "",
      displayName: user.displayName ?? "",
      phoneNumber: user.phoneNumber || "",
      roles: user.roles.map((role) => role.id.toString())
    }
  });

  const handleSubmit = (formData: UserFormValues) => {
    updateUser({
      variables: {
        userId: user.uid ?? "", // TODO: should be id right?
        input: {
          email: formData.email ?? "",
          displayName: formData.displayName ?? "",
          phoneNumber: formData.phoneNumber || null,
          roleIds: formData.roles.map((r) => parseInt(r, 10))
        }
      },
      onCompleted: () => {
        onClose();
      },
      onError: () => {
        form.setError("root", {
          type: "api",
          message: t("userManagement.editForm.actions.edit.error.description")
        });
        toaster.error({
          description: t(
            "userManagement.editForm.actions.edit.error.description"
          )
        });
      }
    });
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          {t("userManagement.editForm.actions.edit.title")}
        </DialogTitle>
        <UserForm
          form={form}
          loading={loading}
          onClose={onClose}
          onSubmit={handleSubmit}
          isEditMode
        />
      </Dialog>
    </>
  );
};
