import clsx from "clsx";
import React from "react";

export const ActionDialogBody = ({
  className,
  children,
  ...props
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div {...props} className={clsx("p-4 overflow-y-auto", className)}>
      {children}
    </div>
  );
};
