import { logger } from "@/lib/logger";
import { PushNotifications } from "@capacitor/push-notifications";
import { useCallback, useState } from "react";

export type RequestPermissionReturnValue = {
  fcmToken: string | null;
  permissionGranted: boolean;
};

export type UseNativePushNotifications = {
  requestPermission: () => Promise<RequestPermissionReturnValue>;
  removeAllListeners: () => Promise<void>;
};

export const useNativePushNotifications = (): UseNativePushNotifications => {
  const [fcmToken, setFcmToken] = useState<string | null>(null);

  const requestPermission = async () => {
    try {
      let status = await PushNotifications.checkPermissions();
      let granted = false;

      if (status.receive === "prompt") {
        status = await PushNotifications.requestPermissions();
      }

      if (status.receive === "granted") {
        await addListeners();

        await PushNotifications.register();

        granted = true;
      }

      if (status.receive === "denied") {
        granted = false;
      }

      return { fcmToken, permissionGranted: granted };
    } catch (error) {
      logger.error("Error requesting permission: ", error);
      throw error;
    }
  };

  const addListeners = useCallback(async () => {
    await PushNotifications.addListener("registration", (token) => {
      setFcmToken(token.value);
    });

    await PushNotifications.addListener("registrationError", (err) => {
      logger.error("Registration error: ", err.error);
    });

    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        // eslint-disable-next-line no-console
        console.log("Push notification received: ", notification);
        // TODO: Update the UI to show the notification, increase notifications counter
      }
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        // eslint-disable-next-line no-console
        console.log(
          "Push notification action performed",
          notification.actionId,
          notification.inputValue
        );
        // TODO: Tap on the notification should result in navigation to the appropriate screen
      }
    );
  }, []);

  const removeAllListeners = async () => {
    await PushNotifications.removeAllListeners();
  };

  return {
    requestPermission,
    removeAllListeners
  };
};
