import { UserItem, UserItemFragment } from "@/app/user-management/UserItem";
import { FragmentOf, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow
} from "@/ui/table/table";
import { TableLoader } from "@/ui/table/TableLoader";
import { FC } from "react";

interface UserTableProps {
  users?: FragmentOf<typeof UserItemFragment>[];
  loading: boolean;
}

export const UsersTable: FC<UserTableProps> = ({ users, loading }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>{t("users.table.name")}</TableHeader>
          <TableHeader>{t("users.table.email")}</TableHeader>
          <TableHeader>{t("users.table.roles")}</TableHeader>
          <TableHeader>{t("users.table.actions")}</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {users ? (
          users.map((user) => {
            const { id } = readFragment(UserItemFragment, user);
            return <UserItem key={id} data={user} />;
          })
        ) : loading ? (
          <TableLoader colSpan={5} />
        ) : null}
      </TableBody>
    </Table>
  );
};
