import { graphql } from "@/lib/data/graphql";
import { useMutation } from "@apollo/client";

const UpdateReadStatusMutation = graphql(`
  mutation UpdateReadStatus($input: UpdateReadStatusInput!) {
    updateReadStatus(input: $input) {
      id
      read
    }
  }
`);

// TODO(BE): check if cache update is correct
export const useUpdateReadStatus = () => {
  const [updateReadStatus, { loading }] = useMutation(UpdateReadStatusMutation);

  return {
    updateReadStatus,
    loading
  };
};
