/* eslint-disable no-restricted-imports */

import i18n, { InitOptions } from "i18next";
import LanguageDetector, {
  DetectorOptions
} from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import deCommon from "@/locales/de/common.json";
import enCommon from "@/locales/en/common.json";
import frCommon from "@/locales/fr/common.json";

export type LanguageCode = "en" | "de" | "fr";

export const LANGUAGE_CODES: LanguageCode[] = ["en", "de", "fr"];

export const resources = {
  en: {
    common: enCommon
  },
  de: {
    common: deCommon
  },
  fr: {
    common: frCommon
  }
};

export const defaultNS = "common";

const options: InitOptions | DetectorOptions = {
  resources,
  defaultNS,
  interpolation: {
    escapeValue: false
  },
  caches: ["localStorage", "cookie"],
  fallbackLng: LANGUAGE_CODES[0]
} as InitOptions;

i18n.use(initReactI18next).use(LanguageDetector).init(options);

export default i18n;

export { useTranslation } from "react-i18next";
