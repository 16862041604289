import { DocumentsPage } from "@/app/documents/DocumentsPage";
import { AppLayout } from "@/app/layouts/AppLayout";
import { NotAuthorized } from "@/app/layouts/NotAuthorized";
import { NotFound } from "@/app/layouts/NotFound";
import { NotificationsPage } from "@/app/notifications/NotificationsPage";
import { RolePermissionsPage } from "@/app/permissions/RolePermissionsPage";
import { UsersPage } from "@/app/user-management/UsersPage";
import { useSession } from "@/lib/auth";
import { useDeeplinks } from "@/lib/native/useDeeplinks";
import { LoadingSpinner } from "@/ui/loading-spinner";
import React from "react";
import { Routes as ReactRouterRoutes, Route } from "react-router-dom";
import { DashboardPage } from "./dashboard/DashboardPage";
import { CommunityImpactPage } from "./impact/CommunityImpactPage";
import { MyImpactPage } from "./impact/MyImpactPage";
import { PerformancePage } from "./performance/PerformancePage";
import { PlantsPage } from "./plants/PlantsPage";
import { ForgotPasswordPage } from "./users/ForgotPasswordPage";

type Props = {
  subject: string;
  Page: React.ElementType;
};

// TODO: revalidate useSession hook
const ProtectedPage = (props: Props) => {
  const { subject, Page } = props;
  const { ability } = useSession();

  if (!ability) {
    return (
      <div className="flex mt-14 justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (ability?.cannot("read", subject)) {
    return <NotAuthorized />;
  }

  return <Page />;
};

export const Routes = () => {
  useDeeplinks();

  return (
    <ReactRouterRoutes>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<DashboardPage />} />
        <Route
          path="/performance/*"
          element={
            <ProtectedPage subject="performance" Page={PerformancePage} />
          }
        />
        <Route
          path="/plants/*"
          element={<ProtectedPage subject="plants" Page={PlantsPage} />}
        />
        <Route
          path="/documents/*"
          element={<ProtectedPage subject="documents" Page={DocumentsPage} />}
        />
        <Route
          path="/users/*"
          element={<ProtectedPage subject="users" Page={UsersPage} />}
        />
        <Route
          path="/permissions/*"
          element={
            <ProtectedPage subject="resources" Page={RolePermissionsPage} />
          }
        />
        <Route
          path="/notifications/*"
          element={
            <ProtectedPage subject="notifications" Page={NotificationsPage} />
          }
        />
        <Route
          path="/my-impact/*"
          element={<ProtectedPage subject="my-impact" Page={MyImpactPage} />}
        />
        <Route
          path="/community-impact/*"
          element={
            <ProtectedPage
              subject="community-impact"
              Page={CommunityImpactPage}
            />
          }
        />
      </Route>
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="*" element={<NotFound />} />
    </ReactRouterRoutes>
  );
};
