import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { formatDate, getDateISO, parseDateFromUrl } from "@/lib/utils/date";
import { ActionDialogToggle } from "@/ui/action-dialog/ActionDialogToggle";
import { Button } from "@/ui/button/button";
import { DateInput } from "@/ui/form/DateInput";
import { Field, Label } from "@/ui/form/fieldset";
import { Input, InputGroup } from "@/ui/form/input";
import { DateFilterDropdown } from "@/ui/table/DateFilterDropdown";
import { TableFilterWrapper } from "@/ui/table/TableFilterWrapper";
import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { compareAsc } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type NotificationsWhereInput = ReturnType<
  typeof graphql.scalar<"NotificationsWhereInput">
>;
export type SetNotificationsWhere = (where: NotificationsWhereInput) => void;

type Props = {
  setWhere: SetNotificationsWhere;
};

export const NotificationsFilters: React.FC<Props> = ({ setWhere }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [filters, setFilters] = useState({
    text: searchParams.get("text") ?? "",
    dateFrom: parseDateFromUrl(searchParams, "dateFrom"),
    dateTo: parseDateFromUrl(searchParams, "dateTo")
  });

  // sync with url
  useEffect(() => {
    const updatedParams = new URLSearchParams(searchParams);
    const filtersToParams = {
      text: filters.text,
      dateFrom: filters.dateFrom ? formatDate(filters.dateFrom) : null,
      dateTo: filters.dateTo ? formatDate(filters.dateTo) : null
    };

    Object.entries(filtersToParams).forEach(([key, value]) => {
      if (!value || value === "all") {
        updatedParams.delete(key);
      } else if (value) {
        updatedParams.set(key, value.toString());
      }
    });

    setSearchParams(updatedParams);
  }, [filters, searchParams, setSearchParams]);

  const isAnyFilterActive = Boolean(
    filters.text || filters.dateFrom || filters.dateTo
  );

  const resetFilters = useCallback(() => {
    setFilters({
      text: "",
      dateFrom: null,
      dateTo: null
    });
  }, []);

  useEffect(() => {
    if (
      filters.dateFrom &&
      filters.dateTo &&
      compareAsc(filters.dateFrom, filters.dateTo) === 1
    ) {
      setFilters((prev) => ({ ...prev, dateTo: null }));
    }
  }, [filters.dateFrom, filters.dateTo]);

  // update query vars
  useEffect(() => {
    const { text, dateFrom, dateTo } = filters;

    const timerId = setTimeout(() => {
      setWhere({
        dateFrom: getDateISO(dateFrom),
        dateTo: getDateISO(dateTo),
        text: text || null
      });
    }, 200);

    return () => clearTimeout(timerId);
  }, [filters, setWhere]);

  return (
    <ActionDialogToggle
      renderTriggerButton={(openModal) => (
        <Button onClick={openModal}>
          {t("notifications.listing.filters.actions.open")}
        </Button>
      )}
      title={t("notifications.listing.filters.title")}
      renderActions={(closeModal) => (
        <>
          <Button
            outline
            onClick={() => {
              resetFilters();
              closeModal();
            }}
          >
            {t("notifications.listing.filters.actions.reset")}
          </Button>
          <Button onClick={closeModal}>
            {t("notifications.listing.filters.actions.showResults")}
          </Button>
        </>
      )}
    >
      <TableFilterWrapper>
        <Field className="min-w-36 mr-auto">
          <Label>{t("notifications.listing.filters.text.label")}</Label>
          <InputGroup>
            <MagnifyingGlassIcon />
            <Input
              name="search"
              placeholder={t("notifications.listing.filters.text.placeholder")}
              aria-label={t("notifications.listing.filters.text.placeholder")}
              value={filters.text}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, text: e.target.value }))
              }
              onClear={() => setFilters((prev) => ({ ...prev, text: "" }))}
            />
          </InputGroup>
        </Field>

        <div className="flex flex-wrap gap-4">
          <Field className="min-w-10">
            <Label>{t("notifications.listing.filters.dateFrom.label")}</Label>
            <DateInput
              value={filters.dateFrom}
              onChange={(date) =>
                setFilters((prev) => ({ ...prev, dateFrom: date }))
              }
              className="mt-3"
              prefix={t("notifications.listing.filters.dateFrom.prefix")}
            />
          </Field>

          <Field className="min-w-10">
            <Label>{t("notifications.listing.filters.dateTo.label")}</Label>
            <DateInput
              value={filters.dateTo}
              minDate={filters.dateFrom ?? undefined}
              onChange={(date) =>
                setFilters((prev) => ({ ...prev, dateTo: date }))
              }
              className="mt-3"
              prefix={t("notifications.listing.filters.dateTo.prefix")}
            />
          </Field>

          {/* TODO(UX): maybe better styling, position for mobile ? */}
          <Field className="mt-auto">
            <DateFilterDropdown
              setDateTo={(date) =>
                setFilters((prev) => ({ ...prev, dateTo: date }))
              }
              setDateFrom={(date) =>
                setFilters((prev) => ({ ...prev, dateFrom: date }))
              }
            />
          </Field>

          <Field className="mt-auto hidden sm:block">
            <Button
              outline
              disabled={!isAnyFilterActive}
              onClick={resetFilters}
            >
              <ArrowUturnLeftIcon />
              <span className="sm:hidden">
                {t("notifications.listing.filters.actions.reset")}
              </span>
            </Button>
          </Field>
        </div>
      </TableFilterWrapper>
    </ActionDialogToggle>
  );
};
