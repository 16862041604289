import { useSession } from "@/lib/auth";
import { useMultiFactorAuthentication } from "@/lib/auth/multi-factor-authentication/useMultiFactorAuthentication";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { FormComponentProps } from "@/ui/form/form";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { useAtom } from "jotai";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import VerificationInput from "react-verification-input";
import { secondFactorFlowAtom } from "../atoms/mfaAtoms";

export type SmsVerificationFormProps = FormComponentProps<
  SmsVerificationFormValues,
  void,
  unknown
>;

export type SmsVerificationFormValues = {
  verificationCode: string;
};

export const SmsVerificationForm = (props: SmsVerificationFormProps) => {
  const { disabled, onError, onSuccess } = props;
  const { t } = useTranslation();
  const { firebaseUser } = useSession();
  const { verifyPhoneEnroll, verifySmsCode, verificationId } =
    useMultiFactorAuthentication();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [flow] = useAtom(secondFactorFlowAtom);

  const { control, handleSubmit } = useForm<SmsVerificationFormValues>();

  const handle = async ({ verificationCode }: SmsVerificationFormValues) => {
    setError(null);

    if (!/^\d{6}$/.test(verificationCode)) {
      setError(t("mfa.smsVerification.invalidCode"));
      return;
    }

    try {
      setLoading(true);

      if (flow === "enroll") {
        await verifyPhoneEnroll(verificationCode, firebaseUser, verificationId);
      }

      if (flow === "second_factor") {
        await verifySmsCode(verificationCode);
      }

      onSuccess?.();
    } catch (error) {
      onError?.(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handle)}>
      <div className="space-y-8 mt-8">
        <h2 className="text-xl font-semibold">
          {t("mfa.smsVerification.title")}
        </h2>
        <p>{t("mfa.smsVerification.codeSent")}</p>
        <div className="flex flex-col">
          <div className="w-full flex justify-center py-4">
            <Controller
              name="verificationCode"
              control={control}
              render={({ field }) => (
                <VerificationInput
                  autoFocus
                  length={6}
                  inputProps={{ autoComplete: "one-time-code" }}
                  onComplete={(value) => handle({ verificationCode: value })}
                  {...field}
                />
              )}
            />
          </div>
          {error && (
            <div
              className="text-xs text-red-500 mt-0 text-center inline-block w-full"
              role="alert"
            >
              {error}
            </div>
          )}
        </div>

        <Button
          type="submit"
          disabled={loading || disabled}
          className="daisyui-btn-md daisyui-btn-block"
          outline
        >
          {loading ? (
            <LoadingSpinner size="sm" />
          ) : (
            t("mfa.smsVerification.verifyCode")
          )}
        </Button>
      </div>
    </form>
  );
};
