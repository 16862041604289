import { createContext, FC, PropsWithChildren, useState } from "react";
import { isNative } from "../utils/isNative";
import { BrowserPushNotificationsProvider } from "./browser/BrowserPushNotificationsProvider";
import { NativePushNotificationsProvider } from "./native/NativePushNotificationsProvider";

export type PushNotificationContextType = {
  /**
   * The FCM token used by Firebase Messaging SDK.
   */
  fcmToken: string | null;
  /**
   * Flag to indicate if the user has granted permission to receive push notifications.
   */
  permissionGranted: boolean;
};

const PushNotificationsContext = createContext<PushNotificationContextType>({
  fcmToken: null,
  permissionGranted: false
});

const PushNotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [fcmToken, setFcmToken] = useState<string | null>(null);
  const [permissionGranted, setPermissionGranted] = useState(false);

  return (
    <PushNotificationsContext.Provider value={{ fcmToken, permissionGranted }}>
      {isNative() ? (
        <NativePushNotificationsProvider
          setFcmToken={setFcmToken}
          setPermissionGranted={setPermissionGranted}
        >
          {children}
        </NativePushNotificationsProvider>
      ) : (
        <BrowserPushNotificationsProvider>
          {children}
        </BrowserPushNotificationsProvider>
      )}
    </PushNotificationsContext.Provider>
  );
};

export { PushNotificationsContext, PushNotificationsProvider };
