import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { formatDateTime } from "@/lib/utils/date";
import { Button } from "@/ui/button/button";
import { Divider } from "@/ui/divider";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle
} from "@/ui/feedback/dialog";
import { Field, Label } from "@/ui/form/fieldset";
import { Text } from "@/ui/typo/text";
import React, { MouseEventHandler } from "react";

type Props = {
  createdAt: string;
  text: string;
  documentType: ReturnType<typeof graphql.scalar<"DocumentType">>;
  isOpen: boolean;
  handleDownloadPdf: (opts?: {
    onSuccess?: VoidFunction;
    onError?: VoidFunction;
  }) => void;
  onClose: VoidFunction;
};

export const NotificationsDetailDialog: React.FC<Props> = ({
  createdAt,
  text,
  onClose,
  isOpen,
  documentType,
  handleDownloadPdf
}) => {
  const { t } = useTranslation();

  const handleDownloadFile: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    handleDownloadPdf({ onSuccess: onClose });
  };

  const handleOnClose: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("notifications.listing.detailDialog.title")}</DialogTitle>
      <DialogBody>
        <Field className="flex gap4 justify-between">
          <Label>{t("notifications.listing.fields.createdAt")}</Label>
          <Text>{formatDateTime(createdAt)}</Text>
        </Field>
        <Divider className="my-4" />
        <Field>
          {/* TODO: not implemented as markdown/rich texts with links, depends on data from BE  */}
          <Label>{t("notifications.listing.fields.text")}</Label>
          <Text>{text}</Text>
        </Field>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={handleOnClose}>
          {t("notifications.listing.detailDialog.cancel")}
        </Button>
        <Button onClick={handleDownloadFile}>
          {t("notifications.listing.actions.download.label", {
            documentType: t(
              `notifications.listing.actions.download.documentTypes.${documentType}`
            )
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
