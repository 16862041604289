import { useSession } from "@/lib/auth";
import { logger } from "@/lib/logger";
import { FC, PropsWithChildren, useEffect } from "react";
import { useRegisterDevice } from "../useRegisterDevice";
import { useNativePushNotifications } from "./useNativePushNotifications";

export type NativePushNotificationsProviderProps = {
  setFcmToken: (fcmToken: string | null) => void;
  setPermissionGranted: (permissionGranted: boolean) => void;
};

export const NativePushNotificationsProvider: FC<
  PropsWithChildren<NativePushNotificationsProviderProps>
> = ({ setFcmToken, setPermissionGranted, children }) => {
  const { firebaseUser } = useSession();
  const { requestPermission, removeAllListeners } =
    useNativePushNotifications();
  const { registerDevice } = useRegisterDevice();

  /**
   * Initialize the native push notifications when the component mounts.
   */
  useEffect(() => {
    const run = async () => {
      const result = await requestPermission();

      setFcmToken(result.fcmToken);
      setPermissionGranted(result.permissionGranted);

      registerDeviceAndFcmToken(result.fcmToken, result.permissionGranted);
    };

    run();

    if (!firebaseUser) {
      removeAllListeners();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseUser]);

  const registerDeviceAndFcmToken = (
    fcmToken: string | null,
    permissionGranted: boolean
  ) => {
    if (firebaseUser && permissionGranted && fcmToken) {
      registerDevice(fcmToken)
        .then(() => {
          // eslint-disable-next-line no-console
          console.log("Device registered successfully");
        })
        .catch((error) => {
          logger.error("Failed to register device", error);
        });
    }
  };

  return <>{children}</>;
};
