import { NotificationItemFragment } from "@/app/notifications/NotificationItem";
import { NotificationsFilters } from "@/app/notifications/NotificationsFilters";
import { NotificationsTable } from "@/app/notifications/NotificationsTable";
import { graphql } from "@/lib/data/graphql";
import { usePaginatedQuery } from "@/lib/data/hooks/usePaginatedQuery";
import { useTranslation } from "@/lib/i18n";
import { Divider } from "@/ui/divider";
import { ListingPageWrapper } from "@/ui/ListingPageWrapper";
import { TablePagination } from "@/ui/table/TablePagination";
import { Heading } from "@/ui/typo/heading";
import { useCallback, useState } from "react";

export const NotificationsPageQuery = graphql(
  `
    query NotificationsPage(
      $where: NotificationsWhereInput
      $skip: Int
      $take: Int
      $orderBy: NotificationsOrderByInput
    ) {
      notifications(
        where: $where
        skip: $skip
        take: $take
        orderBy: $orderBy
      ) {
        items {
          ...NotificationItem
        }
        totalCount
      }
    }
  `,
  [NotificationItemFragment]
);

export const NotificationsPage = () => {
  const { t } = useTranslation();

  const {
    queryResult: { data, loading },
    currentPage,
    totalPages,
    itemsPerPage,
    setWhere,
    orderBy,
    setOrderBy
  } = usePaginatedQuery({
    query: NotificationsPageQuery,
    extractCountFromQuery: (data) => data?.notifications.totalCount
  });

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const handleMultiCheck = useCallback(
    (notifications: { id: number; checked: boolean }[]) => {
      setSelectedIds(() => {
        return notifications
          .filter((notification) => notification.checked)
          .map((notification) => notification.id);
      });
    },
    []
  );
  const handleSingleCheck = useCallback((id: number, checked: boolean) => {
    setSelectedIds((prev) =>
      checked
        ? prev.includes(id)
          ? prev
          : [...prev, id]
        : prev.filter((prevId) => prevId !== id)
    );
  }, []);

  return (
    <ListingPageWrapper>
      <Heading>{t("notifications.listing.title")}</Heading>
      <Divider />

      <NotificationsFilters setWhere={setWhere} />

      <NotificationsTable
        notifications={data?.notifications.items}
        loading={loading}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        onSingleCheck={handleSingleCheck}
        onMultiCheck={handleMultiCheck}
        selectedIds={selectedIds}
      />

      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
        loading={loading}
      />
    </ListingPageWrapper>
  );
};
