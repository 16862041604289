import {
  DocumentItem,
  DocumentItemFragment
} from "@/app/documents/DocumentItem";
import { FragmentOf, readFragment } from "@/lib/data/graphql";
import { useOrderBy } from "@/lib/data/hooks/useOrderBy";
import { useTranslation } from "@/lib/i18n";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow
} from "@/ui/table/table";
import { TableHeaderSortable } from "@/ui/table/TableHeaderSortable";
import { TableLoader } from "@/ui/table/TableLoader";
import { Text } from "@/ui/typo/text";

type DocumentsTableProps = {
  documents?: FragmentOf<typeof DocumentItemFragment>[];
  loading?: boolean;
  orderBy?: Partial<Record<string, "ASC" | "DESC" | null>> | null;
  setOrderBy: (
    updateFn: (
      prev: Partial<Record<string, "ASC" | "DESC" | null>> | null
    ) => Partial<Record<string, "ASC" | "DESC" | null>> | null
  ) => void;
};

export const DocumentsTable: React.FC<DocumentsTableProps> = ({
  documents,
  loading,
  orderBy,
  setOrderBy
}) => {
  const { t } = useTranslation();
  const { onOrderBy } = useOrderBy(setOrderBy);

  if (!loading && (!documents || documents.length === 0)) {
    return <Text>{t("documents.listing.noData")}</Text>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderSortable
            column="name"
            label={t("documents.listing.fields.name")}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
            className="max-w-18"
          />
          <TableHeaderSortable
            column="plant"
            label={t("documents.listing.fields.plant")}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
            className="max-w-18"
          />
          <TableHeaderSortable
            column="label"
            label={t("documents.listing.fields.type.label")}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
          />
          <TableHeaderSortable
            column="createdAt"
            label={t("documents.listing.fields.createdAt")}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
          />
          <TableHeader />
        </TableRow>
      </TableHead>

      <TableBody
        className={loading ? "opacity-40 pointer-events-none" : undefined}
      >
        {documents ? (
          documents.map((doc) => {
            const { id } = readFragment(DocumentItemFragment, doc);
            return <DocumentItem key={id} data={doc} />;
          })
        ) : loading ? (
          <TableLoader colSpan={5} />
        ) : null}
      </TableBody>
    </Table>
  );
};
