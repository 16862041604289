/* eslint-disable react-hooks/exhaustive-deps */
import { DEMO_ACCOUNT } from "@/constants";
import { useSession } from "@/lib/auth";
import { logger } from "@/lib/logger";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  RequestHandler,
  createHttpLink,
  from
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { SentryLink } from "apollo-link-sentry";
import { FC, PropsWithChildren, useMemo } from "react";

export type GraphqlProviderProps = {
  apiUrl: string;
  clientName: string;
  mockRequest: RequestHandler;
};

export const GraphqlProvider: FC<PropsWithChildren<GraphqlProviderProps>> = ({
  apiUrl,
  children,
  mockRequest,
  clientName
}) => {
  const { accessToken, firebaseUser } = useSession();

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: `${apiUrl}/graphql`
    });

    const authLink = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: accessToken ? `Bearer ${accessToken}` : null
        }
      };
    });

    const errorLoggerLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        logger.error(graphQLErrors);
      }

      if (networkError) {
        logger.error(networkError);
      }
    });
    const retryLink = new RetryLink();
    const sentryLink = new SentryLink(); // add breadcrumbs to sentry
    const mockLink = new ApolloLink(mockRequest);

    const NON_MOCKED_OPERATIONS = ["CustomerSearchQuery"];

    const isDemoUser = firebaseUser?.email === DEMO_ACCOUNT.email;

    const link =
      isDemoUser || !firebaseUser
        ? ApolloLink.split(
            (operation) =>
              NON_MOCKED_OPERATIONS.includes(operation.operationName),
            from([errorLoggerLink, retryLink, authLink, httpLink, sentryLink]),
            mockLink
          )
        : from([errorLoggerLink, retryLink, authLink, httpLink, sentryLink]);

    return new ApolloClient({
      devtools: {
        enabled: import.meta.env.ENV_SHORT === "local"
      },
      link,
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              users: {
                merge(_existing, incoming) {
                  return [...incoming];
                }
              }
            }
          },
          Role: {
            fields: {
              resources: {
                merge(_existing, incoming) {
                  return [...incoming];
                }
              }
            }
          }
        }
      }),
      name: clientName
    });
  }, [apiUrl, clientName, accessToken, mockRequest]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
