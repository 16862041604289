import { useTranslation } from "@/lib/i18n";
import { SelectOption } from "@/ui/form/form";
import { Input, InputGroup } from "@/ui/form/input";
import { ListboxOption } from "@/ui/listbox";
import * as Headless from "@headlessui/react";
import { Listbox, ListboxButton, ListboxOptions } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { FC, useState } from "react";

type Props = {
  value: SelectOption[];
  onChange: (value: SelectOption[]) => void;
  options: SelectOption[];
  className?: string;
  placeholder?: string;
  placeholderAllOptions?: string;
  searchFieldPlaceholder?: string;
  autoFocus?: boolean;
  nameForMultipleSelection: string;
  "aria-label"?: string;
} & Omit<Headless.ListboxProps, "value" | "onChange">;

export const ListBoxWithFilter: FC<Props> = ({
  value,
  onChange,
  options,
  placeholder,
  placeholderAllOptions,
  searchFieldPlaceholder,
  className,
  autoFocus,
  nameForMultipleSelection,
  "aria-label": ariaLabel
}) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((opt) => {
          return opt.label.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Listbox value={value} onChange={onChange} multiple>
      <ListboxButton
        autoFocus={autoFocus}
        data-slot="control"
        aria-label={ariaLabel}
        className={clsx([
          className,
          // Basic layout
          "group relative block w-full",
          // Background color + shadow applied to inset pseudo-element, so shadow blends with border in light mode
          "before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white before:shadow",
          // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
          "dark:before:hidden",
          // Hide default focus styles
          "focus:outline-none",
          // Focus ring
          "after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent after:data-[focus]:ring-2 after:data-[focus]:ring-blue-500",
          // Disabled state
          "data-[disabled]:opacity-50 before:data-[disabled]:bg-zinc-950/5 before:data-[disabled]:shadow-none"
        ])}
      >
        <InputGroup
          className={clsx(
            "[&_input]:has-[[data-slot=icon]:first-child]:pl-2 sm:[&_input]:has-[[data-slot=icon]:first-child]:pl-2",
            "[&_input]:has-[[data-slot=icon]:last-child]:pl-[calc(theme(spacing[3.5])-1px)] sm:[&_input]:has-[[data-slot=icon]:last-child]:pl-[calc(theme(spacing.3)-1px)]",
            "[&_input]:has-[[data-slot=icon]:last-child]:pr-[3.5rem] sm:[&_input]:has-[[data-slot=icon]:last-child]:pr-[3.5rem]",
            "[&_button]:right-6"
          )}
        >
          <Input
            placeholder={placeholder}
            readOnly
            value={
              value?.length > 1
                ? t("form.listbox.selectedMultiple", {
                    count: value.length,
                    name:
                      nameForMultipleSelection ??
                      t("form.listbox.selectedMultipleName")
                  })
                : value?.[0]?.label ?? ""
            }
            onClear={() => onChange([])}
          />
          <svg
            className="size-5 stroke-zinc-500 group-data-[disabled]:stroke-zinc-600 sm:size-4 dark:stroke-zinc-400 forced-colors:stroke-[CanvasText]"
            viewBox="0 0 16 16"
            aria-hidden="true"
            fill="none"
            data-slot="icon"
          >
            <path
              d="M5.75 10.75L8 13L10.25 10.75"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.25 5.25L8 3L5.75 5.25"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </InputGroup>
      </ListboxButton>

      <ListboxOptions
        transition
        anchor="bottom start"
        className={clsx(
          // Base styles
          "isolate min-w-[var(--button-width)] w-[var(--button-width)] select-none scroll-py-1 rounded-xl p-1 mt-1",
          // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
          "outline outline-1 outline-transparent focus:outline-none",
          // Handle scrolling when menu won't fit in viewport

          // Popover background
          "bg-white/75 backdrop-blur-xl dark:bg-gray-800",
          // Shadows
          "shadow-lg ring-1 ring-zinc-950/10 dark:ring-inset dark:ring-white/10",
          // Transitions
          "transition-opacity duration-100 ease-in data-[transition]:pointer-events-none data-[closed]:data-[leave]:opacity-0"
        )}
      >
        <InputGroup>
          <MagnifyingGlassIcon />
          <Input
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            placeholder={
              searchFieldPlaceholder ?? t("form.listbox.filterOptions")
            }
            onClear={() => setQuery("")}
          />
        </InputGroup>

        <div
          className={clsx(
            "max-h-[36vh] mt-2",
            "overflow-y-scroll overscroll-contain"
          )}
        >
          {placeholderAllOptions ? (
            <ListboxOption
              value={{ value: "all", label: placeholderAllOptions }}
              className="opacity-50"
            >
              {placeholderAllOptions}
            </ListboxOption>
          ) : null}

          {filteredOptions
            .filter((opt) =>
              query
                ? opt.label?.toLowerCase().includes(query?.toLowerCase())
                : true
            )
            .map((opt) => (
              <ListboxOption key={opt.value} value={opt}>
                {opt.label}
              </ListboxOption>
            ))}
        </div>
      </ListboxOptions>
    </Listbox>
  );
};
