import { Logo } from "@/ui/Logo";
import { LoginForm } from "./LoginForm";

export const LoginPage: React.FC = () => {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100 dark:bg-gray-900 p-4">
      <div className="w-full max-w-md bg-white dark:bg-gray-800 rounded-lg p-8 border border-gray-200 dark:border-gray-700">
        <div className="flex justify-center mb-6">
          <Logo className="px-2" />
        </div>

        <LoginForm />
      </div>
    </div>
  );
};
