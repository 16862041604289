import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Field, Label } from "@/ui/form/fieldset";
import { Select } from "@/ui/form/select";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const RoleFilterPageQuery = graphql(`
  query RoleFilter {
    adminRoles {
      id
      name
    }
  }
`);

type Props = {
  selectedRoleId?: string;
  onChange: (roleId?: string) => void;
};

export const RoleFilter: React.FC<Props> = ({ onChange, selectedRoleId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const { data } = useQuery(RoleFilterPageQuery);

  // select first item after fetching roles
  useEffect(() => {
    const roles = data?.adminRoles;
    const roleIdParam = searchParams.get("role");
    const existsRoleFromUrl = Boolean(
      roles?.find((role) => role.id.toString() === roleIdParam)
    );

    let newSelectedRoleId;
    if (roleIdParam && existsRoleFromUrl) {
      newSelectedRoleId = roleIdParam;
    } else if (roles && roles.length > 0 && roles[0]) {
      newSelectedRoleId = roles[0].id.toString();
    }

    onChange(newSelectedRoleId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // sync with url
  useEffect(() => {
    const updatedParams = new URLSearchParams(searchParams);

    if (selectedRoleId && searchParams.get("role") !== selectedRoleId) {
      updatedParams.set("role", selectedRoleId);
    }

    setSearchParams(updatedParams);
  }, [data, selectedRoleId, searchParams, setSearchParams]);

  if (!data || data?.adminRoles.length === 0) {
    return null;
  }

  return (
    <Field>
      <Label>{t("permissions.listing.filter.role.label")}</Label>
      <Select
        name="role"
        value={selectedRoleId}
        onChange={(e) => onChange(e.target.value)}
        className="sm:w-32 mt-2"
      >
        {data?.adminRoles.map(({ id, name }) => {
          return (
            <option key={id} value={id}>
              {name}
            </option>
          );
        })}
      </Select>
    </Field>
  );
};
