import { useMultiFactorAuthentication } from "@/lib/auth/multi-factor-authentication/useMultiFactorAuthentication";
import { useRecaptcha } from "@/lib/auth/multi-factor-authentication/useRecaptcha";
import { useTranslation } from "@/lib/i18n";
import { Button } from "@/ui/button/button";
import { FormComponentProps } from "@/ui/form/form";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { useAtom } from "jotai";
import { useState } from "react";
import { mfaErrorAtom } from "../atoms/mfaAtoms";

export type SendVerificationCodeProps = FormComponentProps<void, void, unknown>;

export const SendVerificationCode = (props: SendVerificationCodeProps) => {
  const { disabled, onError, onSuccess } = props;
  const { t } = useTranslation();
  const { recaptchaVerifier } = useRecaptcha();
  const { sendVerificationCode, recoverFromError } =
    useMultiFactorAuthentication();

  const [loading, setLoading] = useState(false);
  const [mfaError] = useAtom(mfaErrorAtom);

  const handleClick = async () => {
    try {
      setLoading(true);

      const resolver = recoverFromError(mfaError);
      await sendVerificationCode(resolver, recaptchaVerifier);

      onSuccess?.();
    } catch (error) {
      onError?.(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="space-y-8 mt-8">
        <h2 className="text-xl font-semibold">{t("mfa.sendCode.title")}</h2>
        <p>{t("mfa.sendCode.description")}</p>

        <Button
          className="daisyui-btn-md daisyui-btn-block"
          outline
          type="button"
          onClick={handleClick}
          disabled={loading || disabled}
        >
          {loading ? <LoadingSpinner size="sm" /> : t("mfa.sendCode.button")}
        </Button>
      </div>
    </div>
  );
};
