import { getTheme } from "@/lib/utils/getTheme";
import { ActionDialog } from "@/ui/action-dialog/ActionDialog";
import { ActionDialogBody } from "@/ui/action-dialog/ActionDialogBody";
import { ActionDialogFooter } from "@/ui/action-dialog/ActionDialogFooter";
import { ActionDialogHeader } from "@/ui/action-dialog/ActionDialogHeader";
import React, { PropsWithChildren, useEffect, useState } from "react";

const breakpoints = getTheme().screens;

type Props = {
  breakpoint?: (typeof breakpoints)[keyof typeof breakpoints];
  title: string;
  renderActions: (closeModal: VoidFunction) => React.ReactNode;
  renderTriggerButton: (openModal: VoidFunction) => React.ReactNode;
};

export const ActionDialogToggle: React.FC<PropsWithChildren<Props>> = ({
  children,
  title,
  breakpoint = breakpoints["sm"],
  renderActions,
  renderTriggerButton
}) => {
  const [isMobile, setIsMobile] = useState(
    window?.matchMedia(`(max-width: ${breakpoint})`).matches
  );
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    let timeoutId: number | null = null;

    const handleResize = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        setIsMobile(window?.matchMedia(`(max-width: ${breakpoint})`).matches);
      }, 120) as unknown as number;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoint]);

  return isMobile ? (
    <>
      <div className="flex flex-col items-end">
        {renderTriggerButton(openModal)}
      </div>
      <ActionDialog open={isOpen} onClose={closeModal}>
        <ActionDialogHeader title={title} onClose={closeModal} />
        <ActionDialogBody>{children}</ActionDialogBody>
        <ActionDialogFooter>{renderActions(closeModal)}</ActionDialogFooter>
      </ActionDialog>
    </>
  ) : (
    children
  );
};
