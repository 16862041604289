import { logger } from "@/lib/logger";
import { Observable, Operation, RequestHandler } from "@apollo/client";
import {
  compareAsc as compareDateAsc,
  compareDesc as compareDateDesc
} from "date-fns";
import PerformancePageQuery from "./dashboard.json";
import DocumentsPageQuery from "./documents.json";
import MeQuery from "./me.json";
import NotificationsPageQuery from "./notifications.json";
import PlantsFilterQuery from "./plants-filter.json";
import PlantsPageQuery from "./plants.json";
import UsersPageQuery from "./users.json";

const responses = {
  PerformancePageQuery,
  PlantsFilterQuery,
  PlantsPageQuery,
  UsersPageQuery,
  MeQuery: {
    data: {
      me: MeQuery
    }
  },
  ActivateUser: {
    data: {
      activateUser: MeQuery
    }
  },
  DocumentsPageQuery,
  NotificationsPageQuery,
  RegisterDevice: {
    data: {
      registerDevice: {
        success: true,
        message: "Device registered"
      }
    }
  }
};

export const mockRequest: RequestHandler = (operation: Operation) => {
  return new Observable((observer) => {
    let response = responses[operation.operationName as keyof typeof responses];
    let timeout = 500;

    switch (operation.operationName) {
      case "Me": {
        timeout = 50;
        response = {
          data: {
            me: MeQuery
          }
        };
        break;
      }

      case "UsersPage": {
        response = UsersPageQuery;
        break;
      }

      case "ActivateUser": {
        response = responses.ActivateUser;
        break;
      }

      case "RegisterDevice": {
        response = responses.RegisterDevice;
        break;
      }

      case "PerformancePage": {
        response = PerformancePageQuery;
        break;
      }

      case "NotificationsPage":
      case "NotificationsBadge":
      case "NotificationsNavItem": {
        const { take, skip, where, orderBy } = operation.variables;

        const tmp = [...NotificationsPageQuery.data.notifications.items];
        const orderedItems = orderBy?.createdAt
          ? tmp.sort((a, b) => {
              if (orderBy.createdAt === "ASC") {
                return compareDateAsc(
                  new Date(a.createdAt),
                  new Date(b.createdAt)
                );
              } else {
                return compareDateDesc(
                  new Date(a.createdAt),
                  new Date(b.createdAt)
                );
              }
            })
          : NotificationsPageQuery.data.notifications.items;

        const filteredItemsByText = where?.text
          ? orderedItems.filter((item) =>
              item.text.toLowerCase().includes(where.text.toLowerCase())
            )
          : orderedItems;

        const filteredItemsByReadStatus = where?.read
          ? filteredItemsByText.filter((item) => item.read === where?.read)
          : filteredItemsByText;

        response = {
          ...NotificationsPageQuery,
          data: {
            ...NotificationsPageQuery.data,
            notifications: {
              ...NotificationsPageQuery.data.notifications,
              items: filteredItemsByReadStatus.slice(skip, skip + take),
              totalCount: filteredItemsByReadStatus.length
            }
          }
        };
        break;
      }

      case "DocumentsPage": {
        const { take, skip, where, orderBy } = operation.variables;

        // TODO: not implemented filter by date in mocks

        // TODO: orderBy mocked only for name
        const tmp = [...DocumentsPageQuery.data.documents.items];
        const orderedItems = orderBy?.name
          ? tmp.sort((a, b) => {
              if (orderBy.name === "ASC") {
                return a.name.localeCompare(b.name);
              } else {
                return b.name.localeCompare(a.name);
              }
            })
          : DocumentsPageQuery.data.documents.items;

        const filteredItemsByName = where?.name
          ? orderedItems.filter((item) =>
              item.name.toLowerCase().includes(where.name.toLowerCase())
            )
          : orderedItems;

        const filteredItemsByType = filteredItemsByName.filter((item) =>
          where?.type ? item.type === where.type : true
        );

        const filteredItemsByPlant = filteredItemsByType.filter((item) =>
          where?.plantIds?.length
            ? where?.plantIds.includes(item.plant.id.toString())
            : true
        );

        response = {
          ...DocumentsPageQuery,
          data: {
            ...DocumentsPageQuery.data,
            documents: {
              ...DocumentsPageQuery.data.documents,
              items: filteredItemsByPlant.slice(skip, skip + take),
              totalCount: filteredItemsByPlant.length
            }
          }
        };
        break;
      }

      default:
        if (!response) {
          const error = "Mock not available for following operation";
          logger.error(error, operation);
        }
        break;
    }

    setTimeout(() => {
      observer.next(response);
      observer.complete();
    }, timeout);
  });
};
