import { Dropdown, DropdownButton } from "@/ui/form/dropdown";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { UserAvatar } from "./UserAvatar";
import { UserDropdownMenu } from "./UserDropdownMenu";

export type UserDropdownProps = {
  firstName?: string | null;
};

export const UserDropdown: React.FC<UserDropdownProps> = ({ firstName }) => {
  return (
    <div>
      <Dropdown>
        <DropdownButton outline>
          <UserAvatar className="size-6" />
          <span className="text-xs font-bold">{firstName}</span>
          <ChevronDownIcon />
        </DropdownButton>
        <UserDropdownMenu anchor="bottom end" />
      </Dropdown>
    </div>
  );
};
