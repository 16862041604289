import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { SelectOption } from "@/ui/form/form";
import { ListBoxWithFilter } from "@/ui/form/ListBoxWithFilter";
import { useQuery } from "@apollo/client";
import clsx from "clsx";
import { FC, useEffect, useMemo, useState } from "react";

export const PlantsFilterQuery = graphql(`
  query PlantsFilterQuery {
    plants {
      id
      name
    }
  }
`);

type Props = {
  defaultSelectedValues?: string[];
  onChange: (selected: SelectOption[]) => void;
  className?: string;
  selectFirstEntry?: boolean;
};

export const PlantsFilter: FC<Props> = ({
  defaultSelectedValues = [],
  onChange,
  className,
  selectFirstEntry
}) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<SelectOption[]>([]);

  const { data, loading } = useQuery(PlantsFilterQuery);
  const options = useMemo<SelectOption[]>(
    () =>
      data?.plants.map(({ id, name }) => ({
        value: id.toString(),
        label: name
      })) ?? [],
    [data]
  );

  const handleChange = (selectedItems: SelectOption[]) => {
    setSelected(selectedItems);
    onChange(selectedItems);
  };

  useEffect(() => {
    if (defaultSelectedValues.length > 0) {
      let validatedDefaultSelected = [];
      for (const defaultItem of defaultSelectedValues) {
        // validate defaultSelected values, if isn't in options => clear selection
        const find = options.find((option) => option.value === defaultItem);
        if (!find) {
          validatedDefaultSelected = [];
          break;
        } else {
          validatedDefaultSelected.push(find);
        }
      }
      setSelected(validatedDefaultSelected);
    } else if (selectFirstEntry && options.length > 0 && options[0]) {
      const opt = options[0];
      setSelected([opt]);
      onChange([opt]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  if (loading) {
    return (
      <div
        className={clsx([
          className,
          "w-[150px] h-[32px] dark:bg-white/5 animate-pulse rounded-lg"
        ])}
      ></div>
    );
  }

  if (options.length === 0) {
    return null;
  }

  return (
    <ListBoxWithFilter
      value={selected}
      options={options}
      onChange={handleChange}
      placeholder={t("plants.filters.allPlants")}
      placeholderAllOptions={t("plants.filters.allPlants")}
      className={className}
      nameForMultipleSelection={t("plants.filters.name")}
    />
  );
};
