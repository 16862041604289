import { format, formatISO, parse } from "date-fns";

export function formatDate(dateIsoString: string | Date) {
  return format(new Date(dateIsoString), "dd.MM.yyyy");
}

export function formatDateTime(dateIsoString: string | Date) {
  return format(new Date(dateIsoString), "dd.MM.yyyy HH:mm:ss");
}

export function parseDateFromUrl(
  searchParams: URLSearchParams,
  key: string
): Date | null {
  const paramValue = searchParams.get(key);
  return paramValue ? parse(paramValue, "dd.MM.yyyy", new Date()) : null;
}

export function getDateISO(date: Date | null): string | null {
  return date ? formatISO(date) : null;
}
