import { useSignOut } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import {
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu
} from "@/ui/form/dropdown";
import { useTheme } from "@/ui/Theme";
import { Help } from "@carbon/icons-react";
import {
  ArrowRightStartOnRectangleIcon,
  CogIcon,
  LanguageIcon,
  MoonIcon,
  SunIcon
} from "@heroicons/react/16/solid";
import { useState } from "react";
import { LanguageDialog } from "./LanguageDialog";

type UserDropdownMenuProps = {
  anchor: "top start" | "top end" | "bottom start" | "bottom end";
};

export const UserDropdownMenu = ({ anchor }: UserDropdownMenuProps) => {
  const [signOut] = useSignOut();
  const { theme, toggleTheme } = useTheme();
  const [isLanguageDialogOpen, setIsLanguageDialogOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <LanguageDialog
        open={isLanguageDialogOpen}
        onClose={() => setIsLanguageDialogOpen(false)}
      />
      <DropdownMenu className="min-w-64" anchor={anchor}>
        <DropdownItem>
          <CogIcon />
          <DropdownLabel>{t("menu.settings")}</DropdownLabel>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem onClick={() => setIsLanguageDialogOpen(true)}>
          <LanguageIcon />
          <DropdownLabel>{t("menu.language")}</DropdownLabel>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem onClick={() => toggleTheme()}>
          {theme === "dark" ? (
            <>
              <SunIcon />
              <DropdownLabel>{t("menu.theme.light")}</DropdownLabel>
            </>
          ) : (
            <>
              <MoonIcon />
              <DropdownLabel>{t("menu.theme.dark")}</DropdownLabel>
            </>
          )}
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem
          href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
          target="_blank"
        >
          <Help data-slot="icon" />
          <DropdownLabel>{t("navigation.support")}</DropdownLabel>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem href="#">
          <ArrowRightStartOnRectangleIcon />
          <DropdownLabel onClick={signOut}>{t("menu.signOut")}</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </>
  );
};
