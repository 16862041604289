import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React, { FC, PropsWithChildren, useState } from "react";
import { NavbarItem } from "../navbar/navbar";

export const SidebarLayout = ({
  navbar,
  sidebar,
  footer,
  children
}: React.PropsWithChildren<{
  navbar: React.ReactNode;
  sidebar: React.ReactNode;
  footer?: React.ReactNode;
}>) => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div
      className={clsx(
        "[--sidebar-width:_theme(spacing.64)] [--header-height:_theme(spacing.16)]",
        "relative isolate flex min-h-svh max-h-svh w-full max-lg:flex-col",
        "bg-bg-secondary"
      )}
    >
      {/* Sidebar on desktop */}
      <div className="fixed inset-y-0 left-0 w-[--sidebar-width] max-lg:hidden pl-8 pr-3 py-6 [&_[data-slot=header]]:h-[--header-height]">
        {sidebar}
      </div>

      {/* Sidebar on mobile */}
      <MobileSidebar open={showSidebar} close={() => setShowSidebar(false)}>
        {sidebar}
      </MobileSidebar>

      {/* Navbar on mobile */}
      <MobileHeader onClick={() => setShowSidebar(true)}>{navbar}</MobileHeader>

      {/* Content */}
      <div
        className={clsx(
          "[--compensate-header:_calc(var(--header-height)_+_env(safe-area-inset-top))] [--compensate-footer:_env(safe-area-inset-bottom)]",
          "pt-[--compensate-header] pb-[--compensate-footer] lg:pt-0 lg:pl-[--sidebar-width] lg:pr-8 lg:pb-6",
          "flex flex-col gap-y-3 w-full grow"
        )}
      >
        {/* Topbar on desktop */}
        <div className="max-lg:hidden sticky top-0 z-40 h-[--header-height] mt-6">
          {navbar}
        </div>
        <main
          className={clsx(
            "flex flex-col flex-1 overflow-y-auto lg:min-w-0",
            "h-[calc(100svh_-_var(--compensate-header)_-_var(--compensate-footer))] lg:h-[calc(100%_-_var(--compensate-header)_-_theme(spacing.0)_-_0px)]x",
            "bg-bg-primary lg:rounded-2xl",
            "safe-area-inset-x-padding"
          )}
        >
          <div className="p-4 lg:p-6">
            <div className="mx-auto max-w-screen-2xl">{children}</div>
          </div>
        </main>
      </div>

      {/* Footer on mobile */}
      <MobileFooter>{footer}</MobileFooter>
    </div>
  );
};

// TODO redesign
const MobileSidebar = ({
  open,
  close,
  children
}: React.PropsWithChildren<{ open: boolean; close: () => void }>) => {
  return (
    <Dialog open={open} onClose={close} className="lg:hidden">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/30 transition data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <DialogPanel
        transition
        className="fixed safe-area-inset-bottom safe-area-inset-top w-full max-w-80 p-2 transition duration-300 ease-in-out data-[closed]:-translate-x-full"
      >
        <div className="flex h-full flex-col rounded-lg bg-bg-primary shadow-sm safe-area-inset-x-padding ring-1 ring-zinc-950/5 dark:bg-zinc-900 dark:ring-white/10">
          <div className="-mb-3 px-4 pt-3">
            <CloseButton as={NavbarItem} aria-label="Close navigation">
              <XMarkIcon />
            </CloseButton>
          </div>
          {children}
        </div>
      </DialogPanel>
    </Dialog>
  );
};

// TODO redesign
const MobileHeader: FC<PropsWithChildren<{ onClick: () => void }>> = ({
  onClick,
  children
}) => {
  return (
    <header className="fixed w-full lg:hidden safe-area-inset-top-padding safe-area-inset-x-padding bg-bg-secondary z-[101] flex items-center px-4">
      <div className="flex flex-1 px-2">
        <div className="py-2.5 px-1">
          <NavbarItem onClick={onClick} aria-label="Open navigation">
            <Bars3Icon />
          </NavbarItem>
        </div>
        <div className="min-w-0 flex-1">{children}</div>
      </div>
    </header>
  );
};

// TODO redesign
const MobileFooter: FC<PropsWithChildren> = ({ children }) => {
  return (
    <footer className="fixed bottom-0 flex-shrink-0 safe-area-inset-bottom-padding safe-area-inset-x-padding w-full lg:hidden bg-bg-secondary">
      {children ? (
        <div className="max-w-6xl mx-auto p-4 lg:p-6">{children}</div>
      ) : null}
    </footer>
  );
};
