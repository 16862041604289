import clsx from "clsx";
import React from "react";

export const ActionDialogFooter = ({
  className,
  children,
  ...props
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      {...props}
      className={clsx(
        "sticky z-10 w-full p-4",
        "bg-white dark:bg-zinc-900 border-t dark:border-t-zinc-700",
        className
      )}
    >
      <div className="flex justify-between gap-4">{children}</div>
    </div>
  );
};
