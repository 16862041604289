import { LanguageCode, useTranslation } from "@/lib/i18n";
import { getTheme } from "@/lib/utils/getTheme";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { de, enUS, fr, Locale } from "date-fns/locale";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";

const breakpoints = getTheme().screens;

const locales: Record<LanguageCode, Locale> = { en: enUS, de, fr };

type DateProps = {
  value: Date | null;
  onChange: (date: Date | null) => void;
  placeholder?: string;
  className?: string;
  prefix?: string;
  minDate?: Date;
  maxDate?: Date;
};

export const DateInput: React.FC<DateProps> = ({
  className,
  placeholder,
  prefix,
  value,
  onChange,
  minDate,
  maxDate
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.toLowerCase() as LanguageCode;

  const locale = locales[currentLanguage] ?? locales.de;
  registerLocale(currentLanguage, locale);

  return (
    <DatePicker
      locale={currentLanguage}
      dateFormat="P" // use locale-specific date format
      selected={value}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      withPortal={
        window?.matchMedia(`(max-width: ${breakpoints["sm"]})`).matches
      }
      popperPlacement="bottom-start"
      customInput={
        <Input
          value={value}
          prefix={prefix}
          className={className}
          placeholder={placeholder}
        />
      }
    />
  );
};

const Input = React.forwardRef<
  HTMLInputElement,
  {
    value: Date | null;
    onClick?: () => void;
    className?: string;
    prefix?: string;
    placeholder?: string;
  }
>(({ value, onClick, prefix = "", className, placeholder }, ref) => {
  return (
    <div
      className={clsx(
        className,
        // Layout
        "react-datepicker__input-container max-w-[190px] sm:max-w-[175px]",
        // Hover effects
        "hover:cursor-pointer"
      )}
    >
      <input
        value={prefix + value}
        readOnly
        onClick={onClick}
        ref={ref}
        className={clsx([
          // Layout
          "relative block w-full appearance-none rounded-lg",
          // Padding
          "py-[calc(theme(spacing[2.5])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)] pl-[calc(theme(spacing[3.5])-1px)] sm:pl-[calc(theme(spacing[3])-1px)]",
          "pr-[calc(theme(spacing[10])-1px)] sm:pr-[calc(theme(spacing[9])-1px)]",
          // Typography
          "text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-white",
          // Border and background
          "border border-zinc-950/10 dark:border-white/10 bg-transparent dark:bg-white/5",
          // Focus and invalid states
          "focus:outline-none data-[invalid]:border-red-500 dark:data-[invalid]:border-red-600",
          // Disabled state
          "data-[disabled]:border-zinc-950/20 data-[disabled]:dark:border-white/15 data-[disabled]:dark:bg-white/[2.5%]",
          "before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white before:shadow",
          // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
          "dark:before:hidden"
        ])}
        placeholder={placeholder}
      />
      <button
        onClick={onClick}
        className="absolute top-0 right-0 flex items-center justify-center h-full px-2.5"
      >
        <ChevronDownIcon
          className={clsx(
            // Icon styles
            "size-4 opacity-60 hover:opacity-100",
            "dark:opacity-80 dark:hover:opacity-100"
          )}
        />
      </button>
    </div>
  );
});
Input.displayName = "Input";
