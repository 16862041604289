import * as Headless from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

export const ActionDialogHeader = ({
  className,
  title,
  onClose,
  ...props
}: {
  className?: string;
  onClose: VoidFunction;
  title: string;
}) => {
  return (
    <div
      {...props}
      className={clsx(
        "sticky top-0 z-10 w-full",
        "rounded-t-lg",
        "bg-white p-4 dark:bg-zinc-900 border-b dark:border-b-zinc-700",
        className
      )}
    >
      <Headless.DialogTitle className="text-base/6 font-semibold text-zinc-950 dark:text-white">
        {title}
        {onClose ? (
          <button
            onClick={onClose}
            className="absolute top-0 right-0 flex items-center justify-center h-full px-2.5"
          >
            <XMarkIcon
              className={clsx(
                // Icon styles
                "size-6 opacity-60 hover:opacity-100",
                "dark:opacity-80 dark:hover:opacity-100"
              )}
            />
          </button>
        ) : null}
      </Headless.DialogTitle>
    </div>
  );
};
